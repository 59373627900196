import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import {
  isInProgressAtom,
  errorMessageAtom,
} from "../../atoms/messageBarAtoms";
import {
  IWorkOrderLocation,
  getWorkOrderLocations,
} from "../../services/assetServices";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import { SelectionMode, Stack } from "@fluentui/react";
import PagedList from "../../common/PagedList";
import { MarkerWithInfoWindow } from "./MarkerWithInfoWindow";
import { Polyline } from "./Polyline";
// import DeckGL from "@deck.gl/react";
// import { LineLayer } from "@deck.gl/layers";

export interface IWorkOrderMapParams {
  workOrderId: number | undefined;
}

type TLineData = Array<{
  sourcePosition: Array<number>;
  targetPosition: Array<number>;
}>;

const WorkOrderMap = (params: IWorkOrderMapParams) => {
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const [locations, setLocations] = useState<IWorkOrderLocation[]>([]);
  const [selectedLocation, setSelectedLocation] =
    useState<IWorkOrderLocation>();
  const [lineData, setLineData] = useState<TLineData>([]);

  useEffect(() => {
    const fetchData = async () => {
      const abortController = new AbortController();
      setIsInProgress(true);
      try {
        if (!params.workOrderId) {
          throw new Error("workOrderId not provided");
        }
        const woLocations = await getWorkOrderLocations(
          abortController,
          params.workOrderId,
          50,
          1
        );
        setLocations(woLocations.items);
        setSelectedLocation(
          woLocations.items.length > 0 ? woLocations.items[0] : undefined
        );
        let lastLoc: IWorkOrderLocation;
        const newLineData: TLineData = [];
        woLocations.items.forEach((loc: IWorkOrderLocation) => {
          if (!lastLoc) {
            lastLoc = loc;
            return;
          }
          newLineData.push({
            sourcePosition: [lastLoc.longitude, lastLoc.latitude],
            targetPosition: [loc.longitude, loc.latitude],
          });
          lastLoc = loc;
        });
        setLineData(newLineData);
        console.log(JSON.stringify(newLineData));
      } catch (error: any) {
        console.error("Error:", error);
        setErrorMessage(error.message);
      } finally {
        setIsInProgress(false);
      }
      return () => {
        abortController.abort();
      };
    };

    fetchData();
  }, []);

  if (locations.length === 0 || lineData.length === 0) {
    return <div>Loading...</div>; // or some other loading indicator
  }

  const data = [
    {
      sourcePosition: [151.005457, -33.7311905],
      targetPosition: [151.0053763, -33.7312404],
    },
    {
      sourcePosition: [151.0053763, -33.7312404],
      targetPosition: [151.0057519, -33.7312355],
    },
    {
      sourcePosition: [151.0057519, -33.7312355],
      targetPosition: [151.0493765, -33.7478959],
    },
  ];

  //   const layers = [new LineLayer({ id: "line-layer", lineData })];

  return (
    <Stack horizontal>
      <div
        style={{
          height: "70vh",
          width: "70%",
          position: "relative",
        }}
      >
        <APIProvider
          apiKey={"AIzaSyA6KMpKJv1LWS5JzXpNc9v8ONO8OscYlMc"}
          libraries={["marker"]}
        >
          <Map
            defaultCenter={{
              lat: locations[0].latitude,
              lng: locations[0].longitude,
            }}
            defaultZoom={14}
            disableDefaultUI={true}
            style={{
              pointerEvents: "auto",
              position: "absolute",
              left: 0,
              top: 0,
              zIndex: 1,
            }}
            mapId={"7e43647fdfe8cf8f"}
          >
            {selectedLocation && (
              <MarkerWithInfoWindow
                position={{
                  lat: selectedLocation.latitude,
                  lng: selectedLocation.longitude,
                }}
                title={`WorkOrder: ${params.workOrderId?.toString()}`}
              >
                {new Date(selectedLocation.time + "Z").toLocaleString()}
              </MarkerWithInfoWindow>
            )}
            <Polyline
              points={locations.map((location: IWorkOrderLocation) => ({
                lat: location.latitude,
                lng: location.longitude,
              }))}
            />
          </Map>
        </APIProvider>
      </div>
      <div style={{ maxWidth: "30%" }}>
        <PagedList
          selectionMode={SelectionMode.none}
          selectedTab={""}
          columns={[
            { key: "time", name: "time", maxWidth: 150 },
            { key: "latitude", name: "latitude", maxWidth: 75 },
            { key: "longitude", name: "longitude", maxWidth: 75 },
          ]}
          commandBarItems={[]}
          detailsListWidth={360}
          hideCommandBar={true}
          getAction={async (
            abortController: AbortController,
            pageSize: number,
            pageNo: number
          ) => {
            const woLocations = await getWorkOrderLocations(
              abortController,
              params.workOrderId ?? 0,
              pageSize,
              pageNo
            );
            return {
              ...woLocations,
              items: woLocations.items.map((t: IWorkOrderLocation) => ({
                ...t,
                time: new Date(t.time + "Z").toLocaleString(),
              })),
            };
          }}
        />
      </div>
    </Stack>
  );
};

export default WorkOrderMap;

import {
  DatePicker,
  Dropdown,
  DropdownMenuItemType,
  IDropdownOption,
  IconButton,
  Label,
  Separator,
  Stack,
  TextField,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useState, useEffect } from "react";
import {
  IField,
  IMetadata,
  getAutoCompleteValues,
} from "../services/assetServices";
import {
  columnProps,
  labelColumnStyle,
  valueColumnStyle,
} from "./styles/FormsStyles";
import { errorMessageAtom } from "../atoms/messageBarAtoms";
import { useSetAtom } from "jotai";
import { IValidationError } from "./ValidationHelper";
import {
  AutocompleteSearchBox,
  HighlightTextView,
  ISuggestionItem,
  RenderIf,
} from "../libs";
import { FileSelector } from "./FileSelector";
import MapPickDialog, { ILocation, LocationHelper } from "./MapPickDialog";

export interface IOtherInfoProps {
  metadata: IMetadata;
  otherInfo: string;
  isSaved: React.MutableRefObject<boolean>;
  validationErrors?: IValidationError[];
  onOtherInfoChanged: (newValue: string) => void;
  hideTitle?: boolean;
}

export function cleanOtherInfo(
  otherInfoObj: any,
  otherInfoFields?: IField[]
): string {
  const newOtherInfoObj: any = {};
  otherInfoFields?.forEach((field) => {
    newOtherInfoObj[field.name as keyof typeof newOtherInfoObj] =
      otherInfoObj[field.name];
  });
  return JSON.stringify(newOtherInfoObj);
}

const OtherInfo = (params: IOtherInfoProps) => {
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const [otherInfo, setOtherInfo] = useState(params.otherInfo);

  const getLookupOption = (
    metadata: IMetadata,
    field: IField
  ): IDropdownOption<any>[] => {
    const lookup = metadata.lookups.filter(
      (l) => l.name === field.lookupList
    )[0];
    if (lookup) {
      const lookupValues = lookup.values;
      return lookupValues.map((v) => ({
        key: v.key,
        text: v.value,
        itemType: v.header ? DropdownMenuItemType.Header : undefined,
      }));
    }
    return [];
  };

  const handleOtherInfoChange = (fieldName: string, value?: string) => {
    if (value !== undefined) {
      setOtherInfo((prev) => {
        const newOtherInfo = prev ? JSON.parse(prev) : {};
        newOtherInfo[fieldName] = value;
        params.onOtherInfoChanged(newOtherInfo);
        return JSON.stringify(newOtherInfo);
      });
    }
  };

  useEffect(() => {
    if (params.otherInfo) {
      setOtherInfo(params.otherInfo);
    } else {
      setOtherInfo("{}");
    }
  }, [params]);

  class refValue implements ISuggestionItem {
    constructor(public key: string, private value: string) {}
    getSearchText: () => string = () => {
      return this.value;
    };
    getSuggestionItem(query?: string) {
      return (
        <div key={this.key} className="suggestionItem">
          <div className="suggestionTitleRow row">
            <p className="suggestionTitle col-8">
              <HighlightTextView
                text={this.value}
                filter={query || ""}
              ></HighlightTextView>
            </p>
          </div>
        </div>
      );
    }
  }

  const [suggestions, setSuggestions] = useState<Map<string, refValue[]>>(
    new Map<string, refValue[]>()
  );
  const [selectedSuggestion, setSelectedSuggestion] = useState<
    Map<string, string>
  >(new Map<string, string>());
  const [searchBoxInProgress, setSearchBoxInProgress] = useState(false);

  const [currentField, setCurrentField] = useState<IField>();
  const [isMapPickOpen, { setTrue: showMapPick, setFalse: hideMapPick }] =
    useBoolean(false);

  return (
    <Stack {...columnProps}>
      <RenderIf condition={!params.hideTitle}>
        <Separator alignContent="start">Company-specific info</Separator>
      </RenderIf>
      {!params.metadata || !params.otherInfo ? (
        <></>
      ) : (
        params.metadata?.fields.map((field) => (
          <Stack horizontal>
            <Label style={labelColumnStyle}>{field.label}</Label>
            <RenderIf
              condition={
                field.fieldType === "Number" ||
                field.fieldType === "String" ||
                field.fieldType === "Password"
              }
            >
              <TextField
                style={{ width: valueColumnStyle.width - 2 }}
                onChange={(_, newValue) =>
                  handleOtherInfoChange(field.name, newValue)
                }
                type={
                  field.fieldType === "Number"
                    ? "number"
                    : field.fieldType === "Password"
                    ? "password"
                    : "string"
                }
                multiline={!!field.rows}
                rows={field.rows}
                value={JSON.parse(otherInfo ?? "")[field.name]}
                errorMessage={
                  params.validationErrors?.find(
                    (e) => e.fieldName === field.name
                  )?.message
                }
                readOnly={params.isSaved.current}
              />
            </RenderIf>
            <RenderIf condition={field.fieldType === "Lookup"}>
              <Dropdown
                style={valueColumnStyle}
                options={getLookupOption(params.metadata, field)}
                multiSelect={field.multiSelect}
                selectedKey={
                  field.multiSelect
                    ? undefined
                    : JSON.parse(otherInfo ?? "")[field.name]
                }
                selectedKeys={
                  !!field.multiSelect
                    ? (JSON.parse(otherInfo ?? "")[field.name] ?? "|").split(
                        "|"
                      )
                    : undefined
                }
                onChange={(_, option) => {
                  let value = option?.key.toString();
                  if (field.multiSelect) {
                    const currKeys: string[] = (
                      JSON.parse(otherInfo ?? "")[field.name] ?? "|"
                    )
                      .split("|")
                      .filter((part: string) => part);
                    if (option?.selected) {
                      currKeys.push(option?.key.toString());
                      value = currKeys.join("|");
                    } else {
                      value = currKeys
                        .filter((part: string) => part !== value)
                        .join("|");
                    }
                  }
                  handleOtherInfoChange(field.name, value);
                }}
                errorMessage={
                  params.validationErrors?.find(
                    (e) => e.fieldName === field.name
                  )?.message
                }
                disabled={params.isSaved.current}
              />
            </RenderIf>
            <RenderIf condition={field.fieldType === "Image"}>
              <FileSelector
                value={JSON.parse(otherInfo ?? "")[field.name]}
                purpose="OtherInfo"
                imageSource={
                  JSON.parse(otherInfo ?? "")[`${field.name}_src`] ?? ""
                }
                uploadImage={true}
                onUpload={(id: number, url: string) =>
                  handleOtherInfoChange(field.name, url)
                }
              />
            </RenderIf>
            <RenderIf condition={field.fieldType === "AutoComplete"}>
              <AutocompleteSearchBox
                style={valueColumnStyle}
                id={field.name}
                placeholder={JSON.parse(otherInfo ?? "")[field.name]}
                suggestions={suggestions.get(field.name)}
                inProgress={searchBoxInProgress}
                onClear={() => {
                  if (selectedSuggestion !== undefined) {
                    const selection = new Map(selectedSuggestion);
                    selection?.delete(field.name);
                    setSelectedSuggestion(selection);
                  }
                }}
                onChange={(_, newText) => {
                  if (!newText || newText.trim() === "") {
                    const newSuggestions = new Map(suggestions);
                    newSuggestions?.delete(field.name);
                    setSuggestions(newSuggestions);
                  } else {
                    const dependeeValue = field.dependees
                      ? selectedSuggestion?.get(field.dependees ?? "")
                      : undefined;
                    const dependeeRef = params.metadata.fields.find(
                      (f) => f.name === field.dependees
                    )?.reference;
                    const abortController = new AbortController();
                    setSearchBoxInProgress(true);
                    getAutoCompleteValues(
                      abortController,
                      field.reference ?? "",
                      newText,
                      `${dependeeRef}=${dependeeValue}`
                    )
                      .then((data: { key: string; value: string }[]) => {
                        const newSuggestions = new Map(suggestions);
                        newSuggestions.set(
                          field.name,
                          data.map((kv) => new refValue(kv.key, kv.value))
                        );
                        setSuggestions(newSuggestions);
                      })
                      .catch((e: any) => setErrorMessage(e))
                      .finally(() => setSearchBoxInProgress(false));
                  }
                }}
                onSuggestionClicked={(newValue: string | ISuggestionItem) => {
                  const newRefValue = newValue as refValue;
                  if (selectedSuggestion !== undefined) {
                    const selection = new Map(selectedSuggestion);
                    selection?.set(field.name, `${newRefValue.key}` ?? "");
                    setSelectedSuggestion(selection);
                    handleOtherInfoChange(
                      field.name,
                      `${newRefValue.key}:${newRefValue.getSearchText()}`
                    );
                  }
                }}
              />
            </RenderIf>
            <RenderIf condition={field.fieldType === "Date"}>
              <DatePicker
                styles={{ root: { height: 32 } }}
                style={valueColumnStyle}
                placeholder={
                  otherInfo && JSON.parse(otherInfo)[field.name]
                    ? new Date(JSON.parse(otherInfo)[field.name]).toDateString()
                    : ""
                }
                ariaLabel="Select a date"
                isMonthPickerVisible={false}
                onSelectDate={(selectedDate: Date | null | undefined) => {
                  handleOtherInfoChange(
                    field.name,
                    selectedDate?.toISOString()
                  );
                }}
              />
            </RenderIf>
            <RenderIf condition={field.fieldType === "Coordinate"}>
              <div
                style={{
                  position: "relative",
                  width: valueColumnStyle.width,
                  marginLeft: 0,
                  height: 32,
                }}
              >
                <TextField
                  styles={{
                    field: { height: 32 },
                  }}
                  value={JSON.parse(otherInfo ?? "")[field.name]}
                />
                <IconButton
                  iconProps={{
                    iconName: "Nav2DMapView",
                  }}
                  styles={{
                    root: {
                      position: "absolute",
                      top: 2,
                      right: 2,
                      height: 28,
                    },
                  }}
                  onClick={() => {
                    setCurrentField(field);
                    showMapPick();
                  }}
                />
              </div>
            </RenderIf>
            <RenderIf condition={field.fieldType === "NumberWithUnit"}>
              <Stack horizontal style={valueColumnStyle}>
                <TextField
                  styles={{ root: { width: "68%" } }}
                  value={JSON.parse(otherInfo ?? "")[field.name]}
                  onChange={(_, newValue) =>
                    handleOtherInfoChange(field.name, newValue)
                  }
                />
                <span style={{ width: "2%" }} />
                <Dropdown
                  styles={{ root: { width: "30%" } }}
                  options={getLookupOption(params.metadata, field)}
                  selectedKey={
                    JSON.parse(otherInfo ?? "")[field.name + "_Unit"]
                  }
                  onChange={(_, option) => {
                    let value = option?.key.toString();
                    handleOtherInfoChange(field.name + "_Unit", value);
                  }}
                  errorMessage={
                    params.validationErrors?.find(
                      (e) => e.fieldName === field.name
                    )?.message
                  }
                  disabled={params.isSaved.current}
                />
              </Stack>
            </RenderIf>
          </Stack>
        ))
      )}
      {params.metadata.fields.findIndex(
        (field: IField) => field.fieldType === "Coordinate"
      ) && currentField?.name ? (
        <MapPickDialog
          isModalOpen={isMapPickOpen}
          hideModal={hideMapPick}
          location={LocationHelper.fromString(
            JSON.parse(otherInfo ?? "")[currentField?.name]
          )}
          onMapClick={(newLocation: ILocation) => {
            handleOtherInfoChange(
              currentField.name,
              LocationHelper.locationToString(newLocation)
            );
            hideMapPick();
          }}
        />
      ) : null}
    </Stack>
  );
};
export default OtherInfo;
